<template>
  <div class="discussion" style="padding: 7px">
    <v-row>
      <p class="title">{{ $t("Discussion Comments Requests") }}</p>
    </v-row>
    <div class="preloader" style="text-align: center" v-if="loading">
      <img
        src="../../assets/img/preloader.gif"
        id="preloader-custom"
        alt="preloader"
        class
      />
    </div>
    <v-row class="item" v-for="(post, postIndex) in posts" :key="postIndex">
      <v-col cols="9" sm="9">
        <v-row>
          <v-col sm="2" md="2" lg="2">
            <v-responsive
              v-if="post.post_user_logo"
              style="display: inline-block; margin: auto"
              class="rounded-circle"
            >
              <v-img
                width="50"
                height="50"
                class="member-img rounded-circle"
                style="display: inline-block; margin-top: 15px; margin: auto"
                :src="post.post_user_logo"
              />
            </v-responsive>

            <v-responsive
              v-else
              class="rounded-circle"
              style="display: inline-block"
            >
              <v-img
                width="50"
                height="50"
                class="member-img rounded-circle"
                style="display: inline-block; margin-top: 15px"
                src="../../assets/student.png"
              />
            </v-responsive>
          </v-col>
          <v-col sm="8" md="10" lg="10">
            <div class="person-info text-left" style="margin-left: -1rem">
              <p class="main-color name">
                <strong>{{ post.post_user }}</strong>
              </p>
              <p class="time">{{ post.time }}</p>
            </div>
          </v-col>
        </v-row>
      </v-col>

      <v-col cols="12">
        <div class="topicContent">
          <p class="type">
            <strong>{{ post.title }}</strong>
          </p>
          <!-- <p class="topic">
            {{ post.body }}
          </p>-->
          <chat-line :line="post.body" class="topic"></chat-line>
          <div class="attachments">
            <v-row class="images" v-if="post.attachments.image">
              <v-col
                cols="6"
                sm="3"
                v-for="(image, index) in post.attachments.image"
                :key="index"
              >
                <a :href="image" target="_blank" class="imgContainer">
                  <img :src="image" class="postImg" />
                </a>
              </v-col>
            </v-row>
            <v-row class="other">
              <p style="width: 100%">
                <!-- <strong>Other attachments:</strong> -->
              </p>
              <div class="video" v-if="post.attachments.video.length > 0">
                <v-icon class="main-color">movie</v-icon>
                <a
                  :href="file.url"
                  target="_blank"
                  v-for="(file, index) in post.attachments.video"
                  :key="index"
                  >{{ file.name }}</a
                >
              </div>
              <div class="document" v-if="post.attachments.document.length > 0">
                <v-icon class="main-color">description</v-icon>
                <a
                  :href="file.url"
                  target="_blank"
                  v-for="(file, index) in post.attachments.document"
                  :key="index"
                  >{{ file.name }}</a
                >
              </div>
              <div class="audio" v-if="post.attachments.audio.length > 0">
                <v-icon class="main-color">audiotrack</v-icon>
                <a
                  :href="file.url"
                  target="_blank"
                  v-for="(file, index) in post.attachments.audio"
                  :key="index"
                  >{{ file.name }}</a
                >
              </div>
            </v-row>
          </div>
          <v-divider></v-divider>
          <div class="list-comments">
            <div
              class="comment-item"
              style="padding: 20px"
              v-for="(comment, index) in post.comments"
              :key="index"
            >
              <v-row>
                <v-col cols="8" sm="8">
                  <v-row>
                    <v-col sm="2" md="2" lg="2">
                      <v-responsive
                        v-if="comment.logo"
                        style="display: inline-block"
                        class="rounded-circle"
                      >
                        <v-img
                          width="50"
                          height="50"
                          class="member-img rounded-circle"
                          style="display: inline-block"
                          :src="comment.logo"
                        />
                      </v-responsive>

                      <v-responsive
                        v-else
                        class="rounded-circle"
                        style="display: inline-block"
                      >
                        <v-img
                          width="50"
                          height="50"
                          class="member-img rounded-circle"
                          style="display: inline-block"
                          src="../../assets/avatar.png"
                        />
                      </v-responsive>
                    </v-col>
                    <v-col sm="8" md="10" lg="10">
                      <div
                        class="person-info text-left"
                        style="margin-left: -1rem"
                      >
                        <p class="main-color name">
                          <strong>{{ post.post_user }}</strong>
                        </p>
                        <p class="time">{{ post.time }}</p>
                      </div>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col class="text-right" cols="4" sm="4">
                  <div class="comment-actions" v-if="comment.can_edit">
                    <v-icon
                      class="main-color editAction"
                      style="cursor: pointer"
                      @click="approveComment(comment.id)"
                      >check_circle</v-icon
                    >
                    <v-icon
                      class="main-color editAction"
                      style="cursor: pointer"
                      @click="editComment(postIndex, index)"
                      >edit</v-icon
                    >
                    <v-icon
                      class="main-color deleteAction"
                      style="cursor: pointer"
                      @click="deleteCommentDialogOpen(postIndex, index)"
                      >delete</v-icon
                    >
                  </div>
                </v-col>
              </v-row>
              <div class="comment-body" style="margin-left: 63px">
                <p>{{ comment.body }}</p>
                <div class="attachments">
                  <v-row class="images" v-if="comment.attachments.image">
                    <v-col
                      cols="6"
                      sm="3"
                      v-for="(image, index) in comment.attachments.image"
                      :key="index"
                    >
                      <a :href="image.url" target="_blank" class="imgContainer">
                        <img :src="image.url" class="postImg" />
                      </a>
                    </v-col>
                  </v-row>
                  <v-row class="other">
                    <p style="width: 100%">
                      <!-- <strong>Other attachments:</strong> -->
                    </p>
                    <div
                      class="video"
                      v-if="comment.attachments.video.length > 0"
                    >
                      <v-icon class="main-color">movie</v-icon>
                      <a
                        :href="file.url"
                        target="_blank"
                        v-for="(file, index) in comment.attachments.video"
                        :key="index"
                        >{{ file.name }}</a
                      >
                    </div>
                    <div
                      class="document"
                      v-if="comment.attachments.document.length > 0"
                    >
                      <v-icon class="main-color">description</v-icon>
                      <a
                        :href="file.url"
                        target="_blank"
                        v-for="(file, index) in comment.attachments.document"
                        :key="index"
                        >{{ file.name }}</a
                      >
                    </div>
                    <div
                      class="audio"
                      v-if="comment.attachments.audio.length > 0"
                    >
                      <v-icon class="main-color">audiotrack</v-icon>
                      <a
                        :href="file.url"
                        target="_blank"
                        v-for="(file, index) in comment.attachments.audio"
                        :key="index"
                        >{{ file.name }}</a
                      >
                    </div>
                  </v-row>
                </div>
              </div>
            </div>
          </div>
        </div>
      </v-col>
    </v-row>
    <v-dialog
      v-model="deleteCommentDialog"
      persistent
      max-width="300"
      class="dialog_confirm"
    >
      <v-card>
        <v-card-title class="headline"
          >{{ $t("Confirmation Messag") }}e</v-card-title
        >

        <v-card-text>{{
          $t("Are you sure you want to permanently remove this comment?")
        }}</v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            class="modal-btn-cancel noBtn"
            @click="deleteCommentDialog = false"
            id="noBtn"
            ref="always_focus"
            >{{ $t("No") }}</v-btn
          >
          <v-btn
            id="yesBtn"
            @click="deleteComment"
            class="modal-btn-save yesBtn"
            >{{ $t("Yes") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="editCommentDialog"
      persistent
      max-width="500px"
      scrollable
    >
      <v-card>
        <v-card-title>
          <span class="headline">{{ $t("Edit Comment") }}</span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-form ref="form">
              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <v-textarea
                    v-model="editedComment.body"
                    rows="3"
                    solo
                  ></v-textarea>
                  <div
                    class="attachments"
                    v-if="editedComment.myAttachments.constructor === Object"
                  >
                    <v-row
                      class="images"
                      v-if="editedComment.myAttachments.image.length > 0"
                    >
                      <v-col
                        cols="6"
                        sm="3"
                        v-for="(image, index) in editedComment.myAttachments
                          .image"
                        :key="index"
                      >
                        <div class="delete-attatch">
                          <v-icon
                            class="delete-attatch-icon"
                            @click.prevent="
                              openConfirmDialog(index, image.id, 'image')
                            "
                            color="red"
                            >close</v-icon
                          >
                        </div>
                        <a
                          :href="image.url"
                          target="_blank"
                          class="imgContainer"
                        >
                          <img :src="image.url" class="postImg" />
                        </a>
                      </v-col>
                    </v-row>
                    <v-row class="other">
                      <p style="width: 100%">
                        <!-- <strong>Other attachments:</strong> -->
                      </p>
                      <div
                        class="video"
                        v-if="editedComment.myAttachments.video.length > 0"
                      >
                        <v-col
                          cols="6"
                          v-for="(file, index) in editedComment.myAttachments
                            .video"
                          :key="index"
                        >
                          <div class="delete-attatch">
                            <v-icon
                              class="delete-attatch-icon"
                              @click.prevent="
                                openConfirmDialog(index, file.id, 'video')
                              "
                              color="red"
                              >close</v-icon
                            >
                          </div>
                          <v-icon class="main-color">movie</v-icon>
                          <a :href="file.url" target="_blank">
                            {{ file.name }}
                          </a>
                        </v-col>
                      </div>
                      <div
                        class="document"
                        v-if="editedComment.myAttachments.document.length > 0"
                      >
                        <v-col
                          cols="6"
                          v-for="(file, index) in editedComment.myAttachments
                            .document"
                          :key="index"
                        >
                          <div class="delete-attatch">
                            <v-icon
                              class="delete-attatch-icon"
                              @click.prevent="
                                openConfirmDialog(index, file.id, 'document')
                              "
                              color="red"
                              >close</v-icon
                            >
                          </div>
                          <v-icon class="main-color">description</v-icon>
                          <a :href="file.url" target="_blank">
                            {{ file.name }}
                          </a>
                        </v-col>
                      </div>
                      <div
                        class="audio"
                        v-if="editedComment.myAttachments.audio.length > 0"
                      >
                        <v-col
                          cols="6"
                          v-for="(file, index) in editedComment.myAttachments
                            .audio"
                          :key="index"
                        >
                          <div class="delete-attatch">
                            <v-icon
                              class="delete-attatch-icon"
                              @click.prevent="
                                openConfirmDialog(index, file.id, 'audio')
                              "
                              color="red"
                              >close</v-icon
                            >
                          </div>
                          <v-icon class="main-color">audiotrack</v-icon>
                          <a :href="file.url" target="_blank">
                            {{ file.name }}
                          </a>
                        </v-col>
                      </div>
                    </v-row>
                  </div>

                  <div v-if="validation_errors.about">
                    <p
                      class="red--text"
                      v-for="(error, index) in validation_errors.about"
                      :key="index"
                    >
                      {{ error }}
                    </p>
                  </div>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <p v-if="commentFileNames != ''">{{ commentFileNames }}</p>
          <!-- class="myFileBrowser" -->
          <input
            type="file"
            class="myFileBrowser"
            multiple
            ref="commentattachedited"
            @change="readFilesCommentEdited"
          />
          <v-icon class="main-color" style="cursor: pointer; margin-left: 20px"
            >attach_file</v-icon
          >
          <v-spacer></v-spacer>

          <v-btn
            class="modal-btn-cancel cancelBtn"
            @click="closeCommentDialog"
            >{{ $t("Close") }}</v-btn
          >
          <v-btn
            class="modal-btn-save saveBtn"
            @click="updateComment()"
            :loading="loading"
            :disabled="loading"
            >{{ $t("Save") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="confirmDialog"
      persistent
      max-width="300"
      class="dialog_confirm"
    >
      <v-card>
        <v-card-title class="headline">{{
          $t("Confirmation Message")
        }}</v-card-title>

        <v-card-text
          >{{ $t("Are you sure you want to permanently remove this") }}
          {{ type }}?</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            class="modal-btn-cancel noBtn2"
            @click="confirmDialog = false"
            >{{ $t("No") }}</v-btn
          >
          <v-btn
            @click="deleteItem(deletedItem, indexItem, type)"
            class="modal-btn-save yesBtn2"
            >{{ $t("Yes") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar
      v-model="snack"
      :timeout="snackTime"
      :color="snackColor"
      :right="true"
      :top="true"
    >
      <!-- {{ snackText }} -->

      <span class="white--text">{{ snackText }}</span>

      <v-btn text @click="snack = false">{{ $t("Close") }}</v-btn>
    </v-snackbar>
  </div>
</template>

<script>
import axios from "axios";
import { validationMixin } from "../../mixins/validationMixin";
export default {
  data() {
    return {
      snack: false,
      snackColor: "",
      snackText: "",
      snackTime: 3000,
      postIdEdited: null,
      valid: false,
      edited: false,
      loading: false,
      posts: [],
      validation_errors: [],
      deleteCommentDialog: false,
      deletedComment: {
        postIndex: "",
        commentIndex: "",
      },
      fileNames: "",
      commentFileNames: "",
      editedComment: {
        id: "",
        body: "",
        attachments: [],
        myAttachments: [],
      },
      confirmDialog: false,
      deletedItem: "",
      indexItem: "",
      type: "",
      editCommentDialog: false,
    };
  },
  mixins: [validationMixin],
  methods: {
    closeCommentDialog() {
      this.editCommentDialog = false;
      this.editedComment = {
        id: "",
        body: "",
        attachments: [],
        myAttachments: [],
      };
    },
    readFilesCommentEdited() {
      var _this = this;
      this.$refs.commentattachedited.files.forEach((file) => {
        if (_this.commentFileNames.length == 0) {
          _this.commentFileNames = file.name;
        } else {
          _this.commentFileNames = _this.commentFileNames + ", " + file.name;
        }
        // _this.comment.attachments.push(file);
        console.log(file);
        _this.editedComment.attachments.push(file);
      });
      // console.log(_this.editedComment.attachments);
    },
    editComment(postIndex, commentIndex) {
      this.editedComment.id = this.posts[postIndex].comments[commentIndex].id;
      this.editedComment.body = this.posts[postIndex].comments[
        commentIndex
      ].body;
      this.editedComment.myAttachments = this.posts[postIndex].comments[
        commentIndex
      ].attachments;
      this.editedComment.attachments = [];
      this.editCommentDialog = true;
    },
    deleteItem(item, index, type) {
      let attatchId = item;
      let attatchType = type;
      axios
        .get(
          this.getApiUrl +
            "/discussionboard/post/deleteAttachment/" +
            attatchId,
          {
            headers: {
              Authorization: "Bearer " + localStorage.token,
            },
          }
        )
        .then((response) => {
          if (response.data.status.error == false) {
            console.log(attatchType);
            if (attatchType == "image") {
              this.editedComment.myAttachments.image.splice(index, 1);
            }
            if (attatchType == "document") {
              this.editedComment.myAttachments.document.splice(index, 1);
            }
            if (attatchType == "video") {
              this.editedComment.myAttachments.video.splice(index, 1);
            }
            if (attatchType == "audio") {
              this.editedComment.myAttachments.audio.splice(index, 1);
            }
          }
        });

      this.confirmDialog = false;
    },
    openConfirmDialog(index, imageId, attatchType) {
      this.confirmDialog = true;
      this.deletedItem = imageId;
      this.indexItem = index;
      this.type = attatchType;
    },
    updateComment() {
      const formData = new FormData();
      if (this.editedComment.attachments.length > 0) {
        this.editedComment.attachments.forEach((file) => {
          formData.append("file[]", file);
        });
      }
      formData.append("body", this.editedComment.body);
      // console.log(this.editedComment);
      axios
        .post(
          this.getApiUrl +
            "/discussionboard/comments/update/" +
            this.editedComment.id,
          formData,
          {
            headers: {
              Authorization: "Bearer " + localStorage.token,
            },
          }
        )
        .then((response) => {
          if (response.data.status.error == true) {
            Object.assign(
              this.validation_errors,
              response.data.status.validation_errors
            );
          } else {
            this.editCommentDialog = false;
            this.editedComment = {
              id: "",
              body: "",
              attachments: [],
              myAttachments: [],
            };
            this.getPosts(1);
          }
        });
    },
    deleteCommentDialogOpen(postIndex, commentIndex) {
      this.deletedComment.postIndex = postIndex;
      this.deletedComment.commentIndex = commentIndex;
      this.deleteCommentDialog = true;
    },
    deleteComment() {
      axios
        .get(
          this.getApiUrl +
            "/discussionboard/comments/destroy/" +
            this.posts[this.deletedComment.postIndex].comments[
              this.deletedComment.commentIndex
            ].id,
          {
            headers: {
              Authorization: "Bearer " + localStorage.token,
            },
          }
        )
        .then((response) => {
          if (response.data.status.error == false) {
            this.posts[this.deletedComment.postIndex].comments.splice(
              this.deletedComment.commentIndex,
              1
            );
            this.deleteCommentDialog = false;
          }
        });
    },
    approveComment(commentId) {
      axios
        .post(
          this.getApiUrl + "/discussionboard/comments/approve/" + commentId,
          {
            headers: {
              Authorization: "Bearer " + localStorage.token,
            },
          }
        )
        .then((response) => {
          if (response.data.status.error == true) {
            Object.assign(
              this.validation_errors,
              response.data.status.validation_errors
            );
          } else {
            // this.getGroupData();
            this.snack = true;
            this.snackColor = "green";
            this.snackText = "Comment Approved successfully";

            this.getPosts();
          }
        });
    },
    getPosts() {
      axios
        .get(
          this.getApiUrl +
            "/discussionboard/commentsNeedApproveWhithPosts/" +
            this.$router.currentRoute.params.roomId,
          {
            headers: {
              Authorization: "Bearer " + localStorage.token,
            },
          }
        )
        .then((res) => {
          this.loading = false;
          this.posts = res.data.data;
          if (this.posts.length == 0) {
            this.$router.push(
              {
                path:
                  "/discussionboard/" + this.$router.currentRoute.params.roomId,
              },
              () => {}
            );
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  mounted() {
    this.getPosts();
  },
};
</script>
<style lang="scss" scoped>
@import "../../styles/_variables.scss";
.comment-item {
  background-color: #eef3ff;
  margin-top: 10px;
}
.discussion {
  .title {
    color: #7297ff;
    font-size: px !important;
    font-weight: bold !important;
  }
  .item {
    box-shadow: $box-shadow;
    padding: 10px 20px;
    margin-bottom: 1rem;
    .person-info {
      display: inline-block;
      margin-left: 1rem;
      .name {
        font-size: 16px;
        margin-bottom: 0 !important;
      }
      .time {
        margin-bottom: 0 !important;
        font-size: 12px;
      }
    }
    .topicContent {
      margin-top: 1rem;
      padding-left: 10px;
    }
    .topicInfo {
      border-top: 1px solid #ccc;
      border-bottom: 1px solid #ccc;
    }
  }
}

.myFileBrowser {
  opacity: 0;
  position: absolute;
  left: 0;
  z-index: 999;
}
</style>
